import PropTypes from 'prop-types'
import React from 'react'
import { Columns, Container, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function ServicesHero({ page }) {
  return (
    <Hero className="">
      <Hero.Body>
        <Container className="has-text-centered container content mb-0 pb-0">
          <Columns className="is-centered">
            <Columns.Column className="is-8">
              <h2 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile pr-6 pl-6 is-landing-text">
                Our Services
              </h2>
              <p className="is-size-4">
                Resources, advice, and end-to-end digital product development.
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
ServicesHero.propTypes = {
  page: PropTypes.object,
}
export default ServicesHero
